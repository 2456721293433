<template>
    <section v-show="id_pedido">
        <!-- linea de tiempo -->
        <div class="border-bottom py-2">
            <div class="row mx-0 justify-center mb-2">
                <div v-for="(act, index) in historial" :key="index" class="d-middle" style="width:54px;">
                    <div class="rounded-circle position-relative" :class="act.activo?'bg-general3':'bg-gris'" style="width:40px;height:40px;padding:1px">
                        <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                            <i :class="`f-20 ${act.icon} ${act.activo?'text-general':'text-gris'}`" />
                        </div>
                    </div>
                    <div v-show="index != historial.length-1" class="w-25" :class="act.activo?'bg-general3':'bg-gris'" style="height:3px;" />
                </div>
            </div>
            <p class="text-center text-general mt-3 mb-2 f-600 f-15">
                {{ nombreEstado }}
            </p>
            <template v-if="showHistory">
                <div v-for="(act, index) in historial" :key="index" class="row mx-0">
                    <div class="col-auto">
                        <div class="rounded-circle position-relative" :class="act.activo?'bg-general3':'bg-gris'" style="width:40px;height:40px;padding:1px">
                            <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                                <i :class="`f-20 ${act.icon} ${act.activo?'text-general':'text-gris'}`" />
                            </div>
                        </div>
                        <div v-show="index != historial.length-1" class="mx-auto h-100 py-2" :class="act.activo?'bg-general3':'bg-gris'" style="width:2px;" />
                    </div>
                    <div class="col">
                        <div class="row mx-0">
                            <div class="col px-1">
                                <p class="f-14" :class="act.activo?'text-general':'text-gris'">{{ act.content }}</p>
                                <p class="f-13">{{ act.hora | helper-fecha('hh:mm a DD MMM') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <p class="text-general text-center cr-pointer f-15" @click="showHistory = !showHistory">
                {{ showHistory ? 'Ver menos' : 'Ver más' }}  <i :class="`f-20 icon-${showHistory ? 'angle-up' : 'angle-down'}`" />
            </p>
        </div>
        <div class="border-bottom p-2">
            <div class="row mx-0">
                <div class="" style="width:36px;" />
                <div class="col text-general f-17 f-600 pl-0">
                    Cliente
                </div>
            </div>
            <div class="row mx-0 align-items-center my-2">
                <img class="border rounded-circle bg-whitesmoke" :src="cliente.avatar" width="40" height="40" alt="" />
                <p class="mx-1 f-500 text-general">{{ cliente.nombre }}</p>
                <div class="br-20 px-2 text-general mx-2 bg-light-f5 ml-auto">
                    <span class="mx-2">{{ cliente.cant_pedidos }}</span>
                    <i class="f-20 icon-pedidos" />
                </div>
            </div>
            <div class="row mx-0 my-2">
                <div style="width:41px;">
                    <i class="icon-calendar text-general f-20" />
                </div>
                <div class="col pl-0 pt-1 text-general">
                    <p class="f-15 f-600">Fecha de entrega</p>
                    <p v-if="pedido.uso_horario === 1" class="f-13 pl-0">{{ pedido.fecha_entrega | helper-fecha('ddd DD MMMM hh:mm') }}</p>
                    <p v-else class="f-13 pl-0">No programado</p>
                </div>
            </div>
            <div class="row mx-0 my-2">
                <div style="width:41px;">
                    <i class="icon-location text-general f-20" />
                </div>
                <div class="col pl-0 pt-1 text-general">
                    <p class="f-15 f-600">Dirección de entrega</p>
                    <p class="f-13 pl-0" v-html="formatearTextoHtml(pedido.direccion)" />
                    <p class="f-13 mt-3">{{ pedido.distancia }} Mts Aprox.</p>
                </div>
            </div>
            <div class="row mx-0 my-2 align-items-center">
                <div style="width:41px;">
                    <i class="icon-phone text-general f-22" />
                </div>
                <p class="col f-13 text-general pl-0">{{ cliente.telefono }}</p>
            </div>
            <div class="row mx-0 my-2 align-items-center">
                <div style="width:41px;">
                    <i class="icon-identification text-general f-18" />
                </div>
                <p class="col f-13 text-general pl-0">{{ cliente.identificacion }}</p>
            </div>
            <div class="row mx-0 my-2 text-general align-items-center">
                <i class="icon-money f-20" />
                <p class="f-13 px-3"> <b class="f-15">Forma de pago:</b> {{ funFormaPago(pedido.metodo_pago) }}</p>
                <div v-if="pedido.pago_teorico == 4" class="px-5">
                    <p class="f-13"> <b class="f-15">Estado:</b> {{ pagoOnline.estado == 4 ? 'Aprobado' : 'En espera' }}</p>
                    <p class="f-13"> No. {{ pagoOnline.referencia }}</p>
                </div>
            </div>
            <div class="row mx-0 align-items-center">
                <div class="col-auto pr-2 pl-0">
                    <i class="icon-shopping f-20 text-general" />
                </div>
                <div class="col pl-0 f-17 text-general f-500">
                    Entrega: {{ pedido.cliente_entrega_directa ? 'Cedis' : 'Vendedor' }}
                </div>
            </div>
        </div>


        <!-- Resumen -->
        <div v-show="[1,2,201,202,3,31,32,33,34].indexOf(pedido.estado) > -1" class="p-2 text-general border-bottom">
            <div class="row mx-0">
                <div style="width:41px;">
                    <i class="icon-comision f-16 text-general" />
                </div>
                <div class="col pl-0 f-600 f-17 text-general">
                    Detalle de cobro
                </div>
            </div>
            <div class="row mx-0">
                <div style="width:41px;" />
                <div class="col pl-0">
                    <div class="row mx-0 justify-content-between f-15">
                        <p>Costo de productos ({{ resumenes.final.cantidad_productos }})</p>
                        <b class="text-general">
                            {{ convertMoneyTendero(resumenes.final.val_productos, pedido.idm_moneda) }}
                        </b>
                    </div>
                    <div class="row mx-0 justify-content-between f-15">
                        <p>Descuento</p>
                        <b class="text-general-red">
                            {{ convertMoneyTendero(resumenes.final.val_descuentos, pedido.idm_moneda) }}
                        </b>
                    </div>
                    <div v-if="resumenes.final.val_descuento_lista > 0" class="row mx-0 justify-content-between f-15">
                        <p>Descuento Lista</p>
                        <b class="text-general-red">
                            {{ convertMoneyTendero(resumenes.final.val_descuento_lista, pedido.idm_moneda) }}
                        </b>
                    </div>
                    <div v-if="resumenes.final.id_cupon" class="row mx-0 justify-content-between f-15">
                        <div class="d-middle">
                            <i class="icon-cupon pr-1" />
                            <p>{{ resumenes.final.codigo_cupon }}</p>
                        </div>
                        <b class="text-general-red">
                            {{ convertMoneyTendero(resumenes.final.val_cupon, pedido.idm_moneda) }}
                        </b>
                    </div>
                    <div v-show="convenio != null" class="row mx-0 justify-content-between f-15">
                        <p>Descuento Nomina</p>
                        <b class="text-general-red">
                            {{ convertMoneyTendero(resumenes.final.val_nomina, pedido.idm_moneda) }}
                        </b>
                    </div>
                    <div class="row mx-0 mt-2 f-600 f-15 justify-content-between border-t-gris">
                        <p class="text-general">Sub Total</p>
                        <p class="text-general">{{ convertMoneyTendero(resumenes.final.subtotal, pedido.idm_moneda) }}</p>
                    </div>
                    <div class="row mx-0 justify-content-between f-15">
                        <p>Costo de impuestos</p>
                        <b class="text-general">
                            {{ convertMoneyTendero(resumenes.final.val_impuestos, pedido.idm_moneda) }}
                        </b>
                    </div>
                    <div class="row mx-0 justify-content-between f-15">
                        <p>Costo de envío</p>
                        <b class="text-general">
                            {{ convertMoneyTendero(resumenes.final.val_domicilios, pedido.idm_moneda) }}
                        </b>
                    </div>
                    <div class="row mx-0 mt-2 f-600 f-15 justify-content-between border-t-gris">
                        <p class="text-general">Total a cobrar</p>
                        <p class="text-general">{{ convertMoneyTendero(resumenes.final.val_total, pedido.idm_moneda) }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Resumen -->
        <div v-show="[110,11,12,13].indexOf(pedido.estado) > -1" class="p-2 px-3 text-general border-bottom">
            <div class="row mx-0 px-2  text-general f-600 f-17">
                Detalle de cobro (Antiguo)
            </div>
            <div class="row mx-0 px-2 justify-content-between f-15">
                <p>Costo de productos ({{ resumenes.inicial.cantidad_productos }})</p>
                <b>{{ convertMoneyTendero(resumenes.inicial.val_productos, pedido.idm_moneda) }}</b>
            </div>
            <div class="row mx-0 px-2 justify-content-between f-15">
                <p>Descuento</p>
                <b class="text-general-red">
                    {{ convertMoneyTendero(resumenes.inicial.val_descuentos, pedido.idm_moneda) }}
                </b>
            </div>
            <div class="row mx-0 px-2 justify-content-between f-15">
                <p>Costo de envío</p>
                <b>{{ convertMoneyTendero(resumenes.inicial.val_domicilios, pedido.idm_moneda) }}</b>
            </div>

            <div class="row mx-0 px-2 mt-2 f-600 justify-content-between f-15">
                <p>Total a cobrar</p>
                <p>{{ convertMoneyTendero(resumenes.inicial.val_total, pedido.idm_moneda) }}</p>
            </div>
        </div>
        <!-- Resumen -->
        <div v-show="[110,11,12,13].indexOf(pedido.estado) > -1" class="p-2 px-3 text-general border-bottom">
            <div class="row mx-0 px-2 text-general f-600 f-17">
                Detalle de cobro (Nuevo)
            </div>
            <div class="row mx-0 px-2 justify-content-between f-15">
                <p>Costo de productos ({{ resumenes.final.cantidad_productos }})</p>
                <b>{{ convertMoneyTendero(resumenes.final.val_productos, pedido.idm_moneda) }}</b>
            </div>
            <div class="row mx-0 px-2 justify-content-between f-15">
                <p>Descuento</p>
                <b class="text-general-red">
                    {{ convertMoneyTendero(resumenes.final.val_descuentos, pedido.idm_moneda) }}
                </b>
            </div>
            <div v-if="resumenes.final.val_descuento_lista > 0" class="row mx-0 px-2 justify-content-between f-15">
                <p>Descuento Lista</p>
                <b class="text-general-red">
                    {{ convertMoneyTendero(resumenes.final.val_descuento_lista, pedido.idm_moneda) }}
                </b>
            </div>
            <div v-if="resumenes.final.id_cupon" class="row mx-0 px-2 justify-content-between f-15">
                <div class="d-middle">
                    <i class="icon-cupon pr-1" />
                    <p>{{ resumenes.final.codigo_cupon }}</p>
                </div>
                <b class="text-general-red">
                    {{ convertMoneyTendero(resumenes.final.val_cupon, pedido.idm_moneda) }}
                </b>
            </div>
            <div v-show="convenio != null" class="row mx-0 px-2 justify-content-between f-15">
                <p>Descuento Nomina</p>
                <b class="text-general-red">
                    {{ convertMoneyTendero(resumenes.final.val_nomina, pedido.idm_moneda) }}
                </b>
            </div>
            <div class="row mx-0 px-2 mt-2 f-600 justify-content-between f-15">
                <p>Sub Total</p>
                <p>{{ convertMoneyTendero(resumenes.final.subtotal, pedido.idm_moneda) }} </p>
            </div>
            <div class="row mx-0 px-2 justify-content-between f-15">
                <p>Costo de impuestos</p>
                <b class="text-general">
                    {{ convertMoneyTendero(resumenes.final.val_impuestos, pedido.idm_moneda) }}
                </b>
            </div>
            <div class="row mx-0 px-2 justify-content-between f-15">
                <p>Costo de envío</p>
                <b>{{ convertMoneyTendero(resumenes.final.val_domicilios, pedido.idm_moneda) }}</b>
            </div>
            <div class="row mx-0 px-2 mt-2 f-600 justify-content-between f-15">
                <p>Total a cobrar</p>
                <p>{{ convertMoneyTendero(resumenes.final.val_total, pedido.idm_moneda) }} </p>
            </div>
        </div>
        <div v-show="pedido.directo && resumenes.final.ganancia > 0" class="p-2 border-bottom">
            <div class="row mx-0 my-2 ticket br-8 py-1">
                <div class="col pl-1 px-0 text-general f-15">
                    Ganancias Vendedor
                </div>
                <div class="col-auto ml-auto total-value">
                    {{ convertMoneyTendero(resumenes.final.ganancia, pedido.idm_moneda) }}
                </div>
            </div>
        </div>
        <!-- Inicio convenio nómina -->
        <div v-if="convenio != null" class="border-bottom text-general p-2">
            <div class="row mx-0 my-2 align-items-center">
                <div class="col-auto pl-0 pr-2">
                    <i class="icon-convenio f-22" />
                </div>
                <p class="col f-600 f-17 pl-0 text-general">Convenio de nómina</p>
            </div>
            <div class="row mx-0 align-items-center my-2">
                <div class="col-auto px-3 mr-1" />
                <img class="border br-10 bg-whitesmoke" :src="convenio.convenio_logo != null ? convenio.convenio_logo : '/img/no-imagen/no-imagen-empresa.svg'" width="45" height="45" alt="" />
                <div class="col">
                    <p class="f-15 f-500 text-general">{{ convenio.convenio }}</p>
                    <p class="f-15" :style="convenio.icon === 'bien' ? 'color:#29D884' : 'color:#FF5806;'">
                        <i v-if="convenio.icon === 'bien'" class="icon-ok-circled-outline f-18" style="color:#29D884;" />
                        <i v-else class="icon-attention-alt f-18" style="color:#FF5806;" />
                        {{ convenio.convenio_estado }}
                    </p>
                </div>
            </div>
            <div class="row mx-0 align-items-center my-2">
                <div class="col-auto px-3 mr-1" />
                <div class="col">
                    <p class="f-15  text-general">
                        {{ convenio.convenio_efectivo ? 'Cobrar en efectivo al entregar el pedido' : 'Cobrar en descuento de nómina' }}
                    </p>
                </div>
            </div>
        </div>
        <!-- Fin convenio nómina -->
        <!-- Informacion -->
        <div class="p-2 border-bottom">
            <div class="row mx-0 cr-pointer">
                <div style="width:41px;">
                    <i class="icon-pedidos f-20 text-general" />
                </div>
                <div class="col pl-0 my-auto text-general f-17 f-500">
                    Más información
                </div>
            </div>
            <div class="row mx-0">
                <div style="width:41px;" />
                <div class="col pl-0">
                    <div v-show="pedido.directo" class="row mx-0 align-items-center text-general">
                        <div class="col f-15 pl-0">
                            No. Del AP:
                        </div>
                        <div class="col pr-0 text-right f-15">
                            {{ pedido.id_admin_pedido }}
                        </div>
                    </div>
                    <div v-show="pedido.instrucciones && pedido.instrucciones.length > 300 && ver_mas == false" class="row mx-0">
                        <div class="col f-14 text-general f-500 cr-pointer" style="text-align: end;" @click="ver_mas = true">
                            Ver mas...
                        </div>
                    </div>
                    <p class="text-general f-16 f-600 mt-3">
                        Instrucciones:
                    </p>
                    <p v-if="pedido.instrucciones" class="f-15 text-general" v-html="fun_caracteres(pedido.instrucciones)" />
                    <p v-else class="f-15 text-general">No registra Instrucciones </p>
                </div>
            </div>
        </div>
        <!-- Otorgar Creditos se inahilito para tendero-->
        <!-- <div v-show="pedido.estado === 3 && pedido.pago_estado === 1" class="row mx-0 px-2 mt-3">
            <div class="col-12 px-0">
                <div class="bg-general text-white cr-pointer br-12 py-2 text-center" @click="otorgarCredito()">
                    Otorgar Credito
                </div>
            </div>
        </div> -->
        <!-- <pagos-boton-payu ref="botonPayu" @initPago="initPagoPedido" /> -->
        <modalOtorgarCredito ref="modalOtorgarCredito" @credito="mostar_credito" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Pedidos from '~/services/pedidos/pedidos-tienda'
import Pagos from '~/services/pagos/pagos'
export default {
    components: {
        modalOtorgarCredito: () => import('./partials/modalOtorgarCredito'),
    },
    data(){
        return {
            ver_mas:false,
            showHistory: false,
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos/id_pedido',
            pedido: 'pedidos/pedidos/pedido',
            cliente: 'pedidos/pedidos/cliente',
            historial: 'pedidos/pedidos/historial',
            resumenes: 'pedidos/pedidos/resumenes',
            convenio: 'pedidos/pedidos/convenio',
            pagoOnline: 'pedidos/pedidos/pagoOnline',
        }),
        nombreEstado(){
            if(!this.historial.length) return ''
            let filtered = this.historial.filter(el => el.activo)
            if(!filtered.length) return ''

            return filtered[filtered.length - 1]["content"] ?? '';
        }
    },
    methods: {
        mostar_credito(){
            // this.$store.dispatch('pedidos/pedidos/pedido_credito', this.id_pedido)
            this.pedido.pago_estado = 2
        },
        otorgarCredito(){
            this.$refs.modalOtorgarCredito.toggle();
        },
        fun_caracteres(cadena){
            if(!cadena) return
            let total = cadena.length
            if(this.ver_mas){
                return this.formatearTextoHtml(cadena.substr(0,total))
            }
            return this.formatearTextoHtml(cadena.substr(0,300))
        },
        funFormaPago(metodo){
            switch (this.pedido.pago_teorico){
            case 1:
                return 'Pago en Efectivo'
            case 2:
                return `Pago por ${metodo}`
            case 3:
                return 'Pedido a Credito'
            case 4:
                return 'Pagué en Linea'
            case 5:
                return 'Descontar de Nomina Cliente'
            default:
                return 'Pago en Efectivo'
            }
        },
        async initPagoPedido(){
            try {
                const form = {id_pedido:this.id_pedido}
                const {data} = await Pagos.initPagoPedido(form)
                console.log(data);
                //return

                this.$refs.botonPayu.initBotonPayu(data.infoPago)



            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.br-12{
  border-radius: 12px;
}
.header-credito{
  border-radius: 12px 12px 0 0px;
  height: 50px;
}
.borders{
  border-left: 1px solid #5D5D5D59;
  border-right: 1px solid #5D5D5D59;
  border-bottom: 1px solid #5D5D5D59;
  border-radius: 0px 0px 12px 12px;
}
.text-red{ color: #FF2525; }
.text-green{ color: #28D07B; }
.br-t-12 {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.ticket {
  border: 1px solid #28d07b;
  background-color: #28d07b33;
  .total-value {
    color: #28d07b;
    font-weight: 600;
  }
}
</style>
